import React, { useState } from 'react'
import { ContentProps } from './types'
import styles from './Documents.module.scss'
import { Container, Box, Flex } from '@components/UI/Grid/Grid'
import Label from '@components/UI/Label/Label'

import useTranslation from 'next-translate/useTranslation'
import NextImage from 'next/image'

import Button from '@components/UI/Button/Button'
import { GetDataSourceEntries } from '@lib/datasources'
import SbEditable from 'storyblok-react'
import classnames from 'classnames'

const Select = ({
   title,
   children,
   right,
   showSelected,
}: {
   title: string
   children: any
   right?: boolean
   showSelected?: any
}) => {
   const [active, setActive] = useState(false)
   return (
      <Flex
         onMouseLeave={() => {
            setActive(false)
         }}
         className={[styles.select, right ? styles.right : ''].join(' ')}
         flexDirection="column"
      >
         <Button
            onClick={() => {
               setActive(!active)
            }}
            border={!showSelected}
            dark={showSelected}
         >
            {showSelected || title}
            <NextImage
               src="/icons/material/drop-down-black.svg"
               width="24"
               height="24"
               alt="Chevron icon"
            />
         </Button>
         <Box
            onClick={() => {
               setActive(false)
            }}
            className={classnames(
               styles.subNavigationWrapper,
               active ? styles.active : '',
            )}
         >
            <Flex className={styles.subNavigation} flexDirection="column">
               <div className={styles.title}>
                  {title}
                  <NextImage
                     src="/icons/material/drop-down-black.svg"
                     width="24"
                     height="24"
                     alt="Chevron icon"
                  />
               </div>
               <div className={styles.items}>{children}</div>
            </Flex>
         </Box>
      </Flex>
   )
}

const Documents: React.FC<ContentProps> = ({
   blok,
   fileTypes,
   fund,
   documentsLabel,
}) => {
   const [activeLanguage, setActiveLanguage] = useState('')
   const [activeFileType, setActiveFileType] = useState('')

   const { files } = blok
   const { t } = useTranslation('common')

   let languages = GetDataSourceEntries('languages')
   let allFileTypes = GetDataSourceEntries('file-type')

   const availableLanguages: any = []
   const availableFileTypes: any = []

   const filteredDocuments = files.filter((document: any) => {
      if (fund && fund.length > 0) {
         let found = false
         if (document.fund && document.fund.length === 0) {
            found = true
         } else {
            fund.forEach((name: string) => {
               if (document.fund.includes(name)) {
                  found = true
               }
            })
         }
         if (!found) {
            return false
         }
      }
      if (fileTypes && fileTypes.length > 0) {
         let found = false
         fileTypes.forEach((type: string) => {
            if (document.type.includes(type)) {
               found = true
            }
         })
         if (!found) {
            return false
         }
      }
      if (!availableLanguages.includes(document.language)) {
         availableLanguages.push(document.language)
      }
      if (document.type && document.type.length > 0) {
         document.type.forEach((type: string) => {
            if (!availableFileTypes.includes(type)) {
               availableFileTypes.push(type)
            }
         })
      }
      if (activeLanguage && activeLanguage !== '') {
         let found = false

         if (document.language === activeLanguage) {
            found = true
         }

         if (!found) {
            return false
         }
      }

      if (activeFileType && activeFileType !== '') {
         let found = false

         if (document.type.includes(activeFileType)) {
            found = true
         }

         if (!found) {
            return false
         }
      }

      return true
   })

   languages = languages.filter((lang: any) =>
      availableLanguages.includes(lang.value),
   )
   allFileTypes = allFileTypes.filter((lang: any) =>
      availableFileTypes.includes(lang.value),
   )

   const activeLanguageObject = languages.find(
      (lang) => lang.value === activeLanguage,
   )
   const activeFileObject = allFileTypes.find(
      (fileType) => fileType.value === activeFileType,
   )

   return (
      <Container>
         <Flex alignItems="flex-start" flexDirection="column">
            {documentsLabel && (
               <Box marginBottom={[4, null, null, 10]}>
                  <Label title={documentsLabel} />
               </Box>
            )}
            <Flex
               marginBottom={[5]}
               width={1}
               justifyContent="space-between"
               className={styles.navigation}
            >
               <Select
                  title={t('language')}
                  showSelected={
                     activeLanguageObject && activeLanguageObject.name
                  }
               >
                  <Box
                     as="button"
                     className={classnames(styles.linkWrapper)}
                     onClick={() => {
                        setActiveLanguage('')
                     }}
                     paddingX={[3, null, null, null, null, 3]}
                  >
                     {t('showAll')}
                  </Box>
                  {languages?.length > 0 &&
                     languages.map((language: any) => {
                        return (
                           <Box
                              key={language._uid}
                              as="button"
                              className={classnames(
                                 styles.linkWrapper,
                                 activeLanguage === language.value
                                    ? styles.active
                                    : '',
                              )}
                              onClick={() => {
                                 setActiveLanguage(language.value)
                              }}
                              paddingX={[3, null, null, null, null, 3]}
                           >
                              {language.name}
                           </Box>
                        )
                     })}
               </Select>
               {fileTypes && fileTypes.length < 1 && (
                  <Select
                     showSelected={activeFileObject && activeFileObject.name}
                     right
                     title={t('category')}
                  >
                     <Box
                        as="button"
                        className={classnames(styles.linkWrapper)}
                        onClick={() => {
                           setActiveFileType('')
                        }}
                        paddingX={[3, null, null, null, null, 3]}
                     >
                        {t('showAll')}
                     </Box>
                     {allFileTypes?.length > 0 &&
                        allFileTypes.map((fileType: any) => {
                           return (
                              <Box
                                 key={fileType}
                                 as="button"
                                 className={classnames(
                                    styles.linkWrapper,
                                    activeLanguage === fileType.value
                                       ? styles.active
                                       : '',
                                 )}
                                 onClick={() => {
                                    setActiveFileType(fileType.value)
                                 }}
                                 paddingX={[3, null, null, null, null, 3]}
                              >
                                 {fileType.name}
                              </Box>
                           )
                        })}
                  </Select>
               )}
            </Flex>
            <div className={styles.documentsWrapper}>
               {filteredDocuments &&
                  filteredDocuments.map((document: any) => (
                     <SbEditable content={document} key={document._uid}>
                        <div key={document._uid} className={styles.document}>
                           <div className={styles.first}>
                              <div className={styles.icon}>
                                 <NextImage
                                    src="/icons/material/document.svg"
                                    width="24"
                                    height="24"
                                    alt="Chevron icon"
                                 />
                              </div>
                              <span className={styles.docName}>
                                 {document.title}
                              </span>
                           </div>
                           <span className={styles.docDesc}>
                              {document.description}
                           </span>
                           <div className={styles.download}>
                              {document.file?.filename && (
                                 <Button href={document.file.filename}>
                                    <div className={styles.title}>
                                       {t('download')}
                                    </div>
                                    <div className={styles.arrow}>
                                       <NextImage
                                          src="/icons/material/download-arrow.svg"
                                          width="20"
                                          height="20"
                                          alt="Chevron icon"
                                       />
                                    </div>
                                 </Button>
                              )}
                           </div>
                        </div>
                     </SbEditable>
                  ))}
            </div>
         </Flex>
      </Container>
   )
}

export default Documents
