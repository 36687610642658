import React, { useState } from 'react'
import { FeaturedPostsProps } from './types'
import styles from './FeaturedPosts.module.scss'
import { Box, Container, Flex } from '@components/UI/Grid/Grid'
import Label from '@components/UI/Label/Label'
import { getThemeColor, stringify } from '@lib/helpers'
import { CtaButtonStoryblok } from 'types/storyblok-components'
import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import PostGrid from '@components/PostGrid/PostGrid'
import Image from '@components/UI/Image/Image'
import PostGridCard from '@components/PostGrid/PostGridCard/PostGridCard'
import classnames from 'classnames'

const getPostCategory = (post: any) => {
   const category = post?.full_slug
      ? post?.full_slug.split('/')[post?.full_slug.split('/').length - 2]
      : ''
   const categoryName = category.charAt(0).toUpperCase() + category.slice(1)

   return { name: categoryName || '', value: category || '' }
}

interface Category {
   name: string
   value: string
}
const FeaturedPosts: React.FC<FeaturedPostsProps> = ({ blok }) => {
   const {
      label,
      title,
      description,
      link,
      posts,
      background,
      isLast,
      show_graphic_element,
   } = blok

   const categories: Category[] = []

   if (posts && posts.length > 0) {
      posts.forEach((post: any) => {
         const category = getPostCategory(post) || { name: '', value: '' }

         if (category && !categories.find((c) => c.value === category.value)) {
            categories.push(category)
         }
      })
   }

   const [activeCategory, setActiveCategory] = useState(
      categories && categories?.length > 0
         ? categories[0]
         : { name: '', value: '' },
   )

   const postsByCategory =
      posts && posts.length > 0 && activeCategory?.value !== 'all'
         ? posts.filter((post: any) => {
              const category = getPostCategory(post) || { name: '', value: '' }
              return activeCategory?.value === category?.value
           })
         : posts && posts.length > 0 && activeCategory?.value === 'all'
         ? posts
         : []

   // Sort articles by published date
   const postsSortedByDate =
      postsByCategory && postsByCategory.length > 0
         ? postsByCategory.sort(function (a: any, b: any) {
              const aDate: Date = a?.first_published_at
                 ? new Date(a.first_published_at)
                 : new Date()
              const bDate: Date = b?.first_published_at
                 ? new Date(b?.first_published_at)
                 : new Date()
              return bDate.getTime() - aDate.getTime()
           })
         : []

   return (
      <Flex
         as="section"
         className={styles.wrapper}
         paddingBottom={[10, null, null, 20]}
         bg={getThemeColor(background).background}
         color={getThemeColor(background).color}
      >
         <div id={label ? stringify(label) : ''} className="anchor" />
         <Container>
            {(label || title || description || (link && link?.length > 1)) && (
               <Flex
                  marginBottom={[10, null, null, 20]}
                  width={1}
                  alignItems="flex-end"
                  flexDirection={['column', null, null, 'row']}
               >
                  <Flex
                     flexDirection="column"
                     className={styles.content}
                     width={[1, null, null, 7 / 12]}
                     paddingTop={[10, null, null, 20]}
                  >
                     {label && (
                        <Box marginBottom={[4, null, null, 6]}>
                           <Label title={label} />
                        </Box>
                     )}
                     {title && (
                        <Box
                           as="h3"
                           className={styles.title}
                           marginBottom={[5, null, null, 6]}
                        >
                           {title}
                        </Box>
                     )}
                     {description && (
                        <Box as="p" className={styles.text}>
                           {description}
                        </Box>
                     )}
                  </Flex>
                  <Flex
                     width={5 / 12}
                     justifyContent="flex-end"
                     className="hideMobile"
                  >
                     {link &&
                        link?.length > 0 &&
                        link.map((blok: CtaButtonStoryblok) => {
                           return (
                              <DynamicComponent
                                 key={blok._uid}
                                 background={
                                    getThemeColor(background).background
                                 }
                                 blok={blok}
                              />
                           )
                        })}
                  </Flex>
               </Flex>
            )}
            <Box className="hideMobile">
               <PostGrid posts={posts} />
            </Box>
            <Box className="hideDesktop">
               {
                  // Do not use when only one category is used
                  categories && categories.length > 2 && (
                     <Flex className={styles.tabs}>
                        {categories.map((category: any) => {
                           return (
                              <Flex
                                 justifyContent="center"
                                 alignItems="center"
                                 key={category.value}
                                 as="button"
                                 onClick={() => setActiveCategory(category)}
                                 className={classnames(
                                    styles.tab,
                                    activeCategory.value === category.value
                                       ? styles.active
                                       : '',
                                 )}
                              >
                                 {category.name}
                              </Flex>
                           )
                        })}
                     </Flex>
                  )
               }
               <Box
                  className={styles.mobileGrid}
                  marginTop={[5, null, null, 15]}
               >
                  {postsSortedByDate &&
                     postsSortedByDate?.length > 0 &&
                     postsSortedByDate?.map((article: any) =>
                        article ? (
                           <PostGridCard key={article.id} item={article} />
                        ) : null,
                     )}
               </Box>
            </Box>
            <Box className="hideDesktop" marginTop={5}>
               {link &&
                  link?.length > 0 &&
                  link.map((blok: CtaButtonStoryblok) => {
                     return (
                        <DynamicComponent
                           key={blok._uid}
                           background={getThemeColor(background).background}
                           blok={blok}
                        />
                     )
                  })}
            </Box>
         </Container>
         {show_graphic_element && (
            <Image
               alt="AuAg Graphic"
               className={
                  isLast
                     ? [styles.graphic, styles.last].join(' ')
                     : styles.graphic
               }
               src={
                  'https://a.storyblok.com/f/135633/1908x337/a81f78bb57/graphic.png'
               }
            />
         )}
      </Flex>
   )
}

export default FeaturedPosts
