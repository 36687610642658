import { FC } from 'react'

import PageHome from '@components/PageHome/PageHome'
import Link from '@components/Link/Link'
import SbEditable from 'storyblok-react'
import { DynamicComponentProps, IComponentsProps } from './types'
import TextAndMedia from '@components/Modules/TextAndMedia/TextAndMedia'
import Solutions from '@components/Modules/Solutions/Solutions'
import SolutionCard from '@components/SolutionCard/SolutionCard'
import FeaturedPosts from '@components/Modules/FeaturedPosts/FeaturedPosts'
import PageInvestor from '@components/PageInvestor/PageInvestor'
import ContactModule from '@components/Modules/ContactModule/ContactModule'
import ContactCard from '@components/ContactCard/ContactCard'
import Timeline from '@components/Modules/Timeline/Timeline'
import TabbedContentList from '@components/Modules/TabbedContentList/TabbedContentList'
import FundList from '@components/Modules/FundList/FundList'
import Video from '@components/Video/Video'
import PageHighlight from '@components/Modules/PageHighlight/PageHighlight'
import PeriodicSystem from '@components/Modules/PeriodicSystem/PeriodicSystem'
import TwitterFeed from '@components/Modules/TwitterFeed/TwitterFeed'
import CtaButton from '@components/CtaButton/CtaButton'
import PageSustainability from '@components/PageSustainability/PageSustainability'
import SustainabilityStory from '@components/Modules/SustainabilityStory/SustainabilityStory'
import TwoColumnText from '@components/Modules/TwoColumnText/TwoColumnText'
import SustainabilityCard from '@components/Modules/SustainabilityStory/SustainabilityCard/SustainabilityCard'
import Hero from '@components/Modules/Hero/Hero'
import Banner from '@components/Modules/Banner/Banner'
import Image from '@components/Image/Image'
import Summary from '@components/Modules/Summary/Summary'
import ContentTabs from '@components/Modules/ContentTabs/ContentTabs'
import TabInformation from '@components/Modules/ContentTabs/TabInformation/TabInformation'
import Files from '@components/Files/Files'
import PagePostTemplate from '@components/PagePostTemplate/PagePostTemplate'
import Content from '@components/Modules/Content/Content'
import Investors from '@components/Modules/Investors/Investors'
import FileList from '@components/Modules/FileList/FileList'
import PagePostLanding from '@components/PagePostLanding/PagePostLanding'
import SelectedPosts from '@components/Modules/SelectedPosts/SelectedPosts'
import EditChartData from '@components/EditChartData/EditChartData'
import HoldingData from '@components/HoldingData/HoldingData'
import ChartAndHolding from '@components/ChartAndHolding/ChartAndHolding'
import EditSettings from '@components/EditSettings/EditSettings'
import PageNewsletter from '@components/PageNewsletter/PageNewsletter'
import PageDefault from '@components/PageDefault/PageDefault'
import Contacts from '@components/Modules/ContentTabs/TabInformation/Contacts/Contacts'
import Text from '@components/Text/Text'
import IframeWidget from '@components/Modules/IframeWidget/IframeWidget'
import Table from '@components/Modules/Table/Table'
import HeroWithButtons from '@components/HeroWithButtons/HeroWithButtons'
import TradingViewWidget from '@components/TradingViewWidget/TradingViewWidget'
import CurrentNav from '@components/Modules/CurrentNav/CurrentNav'
import Chart from '@components/Modules/Chart/Chart'
import Highlights from '@components/Modules/Highlights/Highlights'
import PageContact from '@components/PageContact/PageContact'
import ContactForm from '@components/Modules/ContactForm/ContactForm'
import InformationAndDocuments from '@components/Modules/InformationAndDocuments/InformationAndDocuments'
import Documents from '@components/Documents/Documents'
import PageNewsletterSuccess from '@components/PageNewsletterSuccess/PageNewsletterSuccess'
import Redirects from '@components/Redirects/Redirects'

const Components: IComponentsProps = {
   // global
   settings: EditSettings,
   redirects: Redirects,

   // pages
   page_home: PageHome,
   page_investor: PageInvestor,
   page_sustainability: PageSustainability,
   page_fund: PageSustainability,
   page_article: PagePostTemplate,
   page_video: PagePostTemplate,
   page_publication: PagePostTemplate,
   page_podcast: PagePostTemplate,
   page_seo: PagePostTemplate,
   page_news: PagePostTemplate,
   page_post_landing: PagePostLanding,
   page_newsletter: PageNewsletter,
   page_default: PageDefault,
   page_contact: PageContact,
   page_newsletter_success: PageNewsletterSuccess,

   // modules
   hero: Hero,
   hero_with_buttons: HeroWithButtons,
   text_and_media: TextAndMedia,
   solutions: Solutions,
   featured_posts: FeaturedPosts,
   contact_module: ContactModule,
   timeline: Timeline,
   tabbed_content_list: TabbedContentList,
   fund_list: FundList,
   page_highlight: PageHighlight,
   periodic_system: PeriodicSystem,
   twitter_feed: TwitterFeed,
   sustainability_story: SustainabilityStory,
   two_column_text: TwoColumnText,
   banner: Banner,
   summary: Summary,
   content_tabs: ContentTabs,
   content: Content,
   investors: Investors,
   file_list: FileList,
   selected_posts: SelectedPosts,
   chart_and_holdings: ChartAndHolding,
   chart_data: EditChartData,
   holding_data: HoldingData,
   iframe_widget: IframeWidget,
   table: Table,
   tradingview_widget: TradingViewWidget,
   current_nav: CurrentNav,
   contact_form: ContactForm,
   information_and_documents: InformationAndDocuments,

   // custom modules
   chart: Chart,
   highlights: Highlights,

   // components
   link: Link,
   cta_button: CtaButton,
   solution_card: SolutionCard,
   contact_card: ContactCard,
   video: Video,
   sustainability_card: SustainabilityCard,
   image: Image,
   tab_information: TabInformation,
   files: Files,
   contacts: Contacts,
   text: Text,
   documents: Documents,
}

const DynamicComponent: FC<DynamicComponentProps> = ({
   blok,
   story,
   stories,
   className,
   onClick,
   background,
   funds,
   navData,
   currentNavData,
   documents,
   periodicItems,
   withScrim,
   holdingData,
   type,
   navDataDailyA,
   navDataDailyB,
   sharedNavData,
   isNews,
   body,
}) => {
   if (blok) {
      if (typeof Components[blok.component] !== 'undefined') {
         const Component = Components[blok.component]
         return (
            <SbEditable content={blok}>
               <Component
                  blok={blok}
                  story={story}
                  currentNavData={currentNavData}
                  documents={documents}
                  className={className}
                  onClick={onClick}
                  stories={stories}
                  background={background}
                  funds={funds}
                  body={body}
                  isNews={isNews}
                  navData={navData}
                  navDataDailyA={navDataDailyA}
                  sharedNavData={sharedNavData}
                  navDataDailyB={navDataDailyB}
                  holdingData={holdingData}
                  periodicItems={periodicItems}
                  withScrim={withScrim}
                  type={type}
               />
            </SbEditable>
         )
      } else {
         return <p>{blok.component} is not yet defined.</p>
      }
   }
   return null
}

export default DynamicComponent
