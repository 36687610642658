import React from 'react'
import { TextAndMediaProps } from './types'
import styles from './TextAndMedia.module.scss'
import { Flex, Container, Box, Row, Column } from '@components/UI/Grid/Grid'
import Label from '@components/UI/Label/Label'
import Markdown from '@components/UI/Markdown/Markdown'
import { getThemeColor, stringify } from '@lib/helpers'
import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import classnames from 'classnames'
import GraphicWave from '@components/UI/GraphicWave/GraphicWave'

const TextAndMedia: React.FC<TextAndMediaProps> = ({ blok }) => {
   const {
      label,
      title,
      preamble,
      text,
      background,
      media,
      cta,
      media_placement = 'bottom',
      media_style = 'normal',
   } = blok

   return (
      <Flex
         as="section"
         width={1}
         bg={getThemeColor(background).background}
         color={getThemeColor(background).color}
         className={styles.wrapper}
      >
         <div id={label ? stringify(label) : ''} className="anchor" />
         <Container>
            <Box
               paddingTop={[10, null, null, 20]}
               paddingBottom={[
                  media && media?.length > 0 && media_placement === 'bottom'
                     ? 0
                     : 10,
                  null,
                  null,
                  20,
               ]}
            >
               <Row
                  justifyContent="space-between"
                  flexDirection={[
                     media_placement === 'right' ? 'column-reverse' : 'column',
                     null,
                     null,
                     media_placement === 'right' ? 'row-reverse' : 'row',
                  ]}
               >
                  {media &&
                     media?.length > 0 &&
                     media_placement !== 'bottom' && (
                        <Column
                           width={[1, null, null, 5 / 12]}
                           justifyContent="flex-end"
                           marginBottom={[
                              media_placement === 'left' ? 6 : 0,
                              null,
                              null,
                              0,
                           ]}
                           marginTop={
                              media_placement === 'right'
                                 ? [7, null, null, 0]
                                 : 0
                           }
                        >
                           <Box
                              className={classnames(
                                 styles.media,
                                 media_style === 'rounded'
                                    ? styles.rounded
                                    : '',
                              )}
                           >
                              {media.map((blok: any) => (
                                 <DynamicComponent
                                    key={blok._uid}
                                    blok={blok}
                                 />
                              ))}
                           </Box>
                        </Column>
                     )}
                  <Column
                     width={[
                        1,
                        null,
                        null,
                        media_placement !== 'bottom' ? 6 / 12 : 7 / 12,
                     ]}
                  >
                     <Flex alignItems={['flex-start']} flexDirection="column">
                        {label && (
                           <Box marginBottom={[4, null, null, 6]}>
                              <Label title={label} />
                           </Box>
                        )}
                        {title && (
                           <Box
                              as="h3"
                              className={classnames(
                                 styles.title,
                                 media_placement !== 'bottom'
                                    ? styles.small
                                    : '',
                              )}
                              marginBottom={[5, null, null, 6]}
                           >
                              {title}
                           </Box>
                        )}
                        {preamble && (
                           <Box marginBottom={[5, null, null, 5]}>
                              <Markdown className={styles.preamble}>
                                 {preamble}
                              </Markdown>
                           </Box>
                        )}
                        {text && (
                           <Box>
                              <Markdown className={styles.text}>
                                 {text}
                              </Markdown>
                           </Box>
                        )}
                        {cta &&
                           cta.length > 0 &&
                           cta.map((blok: any) => {
                              return (
                                 <Box
                                    key={blok._uid}
                                    marginTop={[5, null, null, 12]}
                                 >
                                    <DynamicComponent blok={blok} />
                                 </Box>
                              )
                           })}
                     </Flex>
                  </Column>
               </Row>
            </Box>
         </Container>
         {media && media?.length > 0 && media_placement === 'bottom' && (
            <React.Fragment>
               <Box className={styles.graphicWrapper}>
                  <GraphicWave className={styles.graphic} />
               </Box>
               <Flex
                  paddingX={[4, null, null, 0]}
                  paddingTop={[7, null, null, 0]}
                  paddingBottom={[10, null, null, 0]}
                  width={1}
                  justifyContent="flex-end"
               >
                  {media.map((blok: any) => (
                     <Box
                        width={[1, null, null, 2 / 3]}
                        key={blok._uid}
                        className={classnames(
                           styles.media,
                           media_style === 'rounded' ? styles.rounded : '',
                        )}
                     >
                        <DynamicComponent blok={blok} />
                     </Box>
                  ))}
               </Flex>
            </React.Fragment>
         )}
      </Flex>
   )
}

export default TextAndMedia
