import React, { Fragment, useRef } from 'react'
import { SolutionsProps } from './types'
import styles from './Solutions.module.scss'
import { Box, Container, Flex, Row, Column } from '@components/UI/Grid/Grid'
import Label from '@components/UI/Label/Label'
import Markdown from '@components/UI/Markdown/Markdown'
import { stringify } from '@lib/helpers'
import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import { SolutionCardStoryblok } from 'types/storyblok-components'
import classnames from 'classnames'
import NextImage from 'next/image'
import Image from '@components/UI/Image/Image'
const Solutions: React.FC<SolutionsProps> = ({ blok }) => {
   const itemsRef: any = useRef(null)
   const {
      label,
      title,
      description,
      solutions,
      column_content,
      show_as_list,
   } = blok

   const useDesktopSlider = solutions && solutions.length > 3
   return (
      <Flex
         as="section"
         flexDirection="column"
         width={1}
         className={styles.wrapper}
         paddingBottom={[10, null, null, 20]}
      >
         <div id={label ? stringify(label) : ''} className="anchor" />
         <Container>
            <Row>
               <Column width={1} paddingTop={[10, null, null, 20]}>
                  <Box marginBottom={[4, null, null, 6]} display="inline-block">
                     {label && <Label title={label} />}
                  </Box>
                  <Box
                     className={styles.title}
                     as="h3"
                     marginBottom={[5, null, null, 6]}
                  >
                     {title}
                  </Box>
               </Column>
            </Row>
            <Row justifyContent="space-between">
               <Column width={[1, null, null, 6 / 12]}>
                  {description && (
                     <Markdown className={styles.text}>{description}</Markdown>
                  )}
               </Column>
               {column_content && column_content?.length > 0 && (
                  <Column
                     width={[1, null, null, 5 / 12]}
                     marginTop={[5, null, null, 0]}
                  >
                     {column_content.map((blok: any) => (
                        <Box key={blok?._uid} className={styles.borderImage}>
                           <DynamicComponent blok={blok} />
                        </Box>
                     ))}
                  </Column>
               )}
            </Row>
         </Container>
         {show_as_list && show_as_list === true ? (
            <div
               className={[
                  styles.list,
                  solutions && solutions.length < 5 ? styles.half : '',
               ].join(' ')}
            >
               {solutions &&
                  solutions?.length > 0 &&
                  solutions.map((blok: SolutionCardStoryblok) => {
                     const { title, text, link, hover_image } = blok

                     return (
                        <div key={blok._uid} className={styles.listItem}>
                           {hover_image && hover_image.filename && (
                              <div className={styles.listImageWrapper}>
                                 <Image
                                    alt={title}
                                    src={hover_image.filename}
                                 />
                              </div>
                           )}
                           <h3>{title}</h3>
                           {text && (
                              <Markdown className={styles.text}>
                                 {text}
                              </Markdown>
                           )}
                           {link && link?.length > 0
                              ? link.map((blok: any) => {
                                   const linkItem =
                                      blok.link && blok.link[0]
                                         ? blok.link[0]
                                         : {}

                                   return (
                                      <Box
                                         marginTop={['24px']}
                                         key={blok._uid}
                                         className={styles.button}
                                      >
                                         <DynamicComponent
                                            type="button"
                                            blok={{
                                               ...blok,
                                               link: [
                                                  {
                                                     ...linkItem,
                                                     link: {
                                                        chached_url: '',
                                                     },
                                                  },
                                               ],
                                            }}
                                         />
                                      </Box>
                                   )
                                })
                              : null}
                        </div>
                     )
                  })}
            </div>
         ) : (
            <Fragment>
               <Box
                  marginTop={[10, null, null, 20]}
                  className={classnames(styles.itemsWrapper, useDesktopSlider)}
                  paddingLeft={[
                     0,
                     null,
                     null,
                     useDesktopSlider
                        ? 0
                        : 'calc(var(--container-side-padding) / 2)',
                  ]}
                  paddingRight={[
                     0,
                     null,
                     null,
                     useDesktopSlider
                        ? 0
                        : 'calc(var(--container-side-padding) / 2)',
                  ]}
               >
                  <Box
                     ref={itemsRef}
                     className={styles.items}
                     paddingX={[
                        0,
                        null,
                        null,
                        useDesktopSlider
                           ? 'calc(var(--container-side-padding) - 48px)'
                           : 0,
                     ]}
                  >
                     {solutions &&
                        solutions?.length > 0 &&
                        solutions.map((blok: SolutionCardStoryblok) => {
                           return (
                              <Box
                                 key={blok._uid}
                                 className={styles.cardWrapper}
                                 flexShrink={0}
                                 width={
                                    useDesktopSlider
                                       ? [3 / 4, null, null, 5 / 16]
                                       : [3 / 4, null, null, 1 / 3]
                                 }
                              >
                                 <Box
                                    className={styles.card}
                                    paddingLeft={[
                                       4,
                                       null,
                                       null,
                                       useDesktopSlider
                                          ? 10
                                          : 'calc(var(--container-side-padding) / 2)',
                                    ]}
                                    paddingRight={[
                                       4,
                                       null,
                                       null,
                                       useDesktopSlider
                                          ? 10
                                          : 'calc(var(--container-side-padding) / 2)',
                                    ]}
                                 >
                                    <DynamicComponent blok={blok} />
                                 </Box>
                              </Box>
                           )
                        })}
                  </Box>
                  <Flex
                     justifyContent="center"
                     className={classnames(
                        styles.nav,
                        useDesktopSlider ? styles.desktopNav : '',
                     )}
                     marginTop={[10, null, null, 20]}
                  >
                     {solutions &&
                        solutions?.length > 0 &&
                        solutions.map(
                           (blok: SolutionCardStoryblok, index: number) => {
                              return (
                                 <Box
                                    onClick={() => {
                                       console.log('Click')
                                       if (
                                          itemsRef &&
                                          itemsRef.current &&
                                          itemsRef.current.children &&
                                          itemsRef.current.children[0]
                                       ) {
                                          const width =
                                             itemsRef.current.children[0]
                                                .offsetWidth
                                          console.log('Scroll')

                                          itemsRef.current.scroll(
                                             width * index,
                                             0,
                                          )
                                       }
                                    }}
                                    aria-label={`Slide ${index + 1}`}
                                    key={`nav-${blok._uid}`}
                                    as="button"
                                    className={styles.navButton}
                                 >
                                    <Box className={styles.arrow}>
                                       <NextImage
                                          src="/icons/chevron-left.svg"
                                          width="10.8"
                                          height="17.6"
                                          alt="Chevron icon"
                                          className={styles.arrowWhite}
                                       />
                                       <NextImage
                                          src="/icons/chevron-left-grey.svg"
                                          width="10.8"
                                          height="17.6"
                                          alt="Chevron icon"
                                          className={styles.arrowGrey}
                                       />
                                    </Box>
                                 </Box>
                              )
                           },
                        )}
                  </Flex>
               </Box>
            </Fragment>
         )}
      </Flex>
   )
}

export default Solutions
