import React from 'react'
import LinkComponent from '@components/UI/LinkComponent/LinkComponent'
import styles from './Button.module.scss'

interface Props {
   children: any
   onClick?: any
   className?: string
   href?: string
   title?: string
   type?: 'button' | 'submit' | 'reset' | undefined
   border?: boolean
   dark?: boolean
}
const Button: React.FC<Props> = ({
   children,
   title,
   href,
   onClick,
   className,
   type,
   border,
   dark,
}) => {
   const classNames = [className, styles.button]
   // let background = '#fff'
   if (border) {
      classNames.push(styles.border)
   }
   if (dark) {
      classNames.push(styles.dark)
   }
   return href ? (
      <LinkComponent
         href={href}
         className={classNames.join(' ')}
         onClick={onClick}
         title={title}
      >
         {children}
      </LinkComponent>
   ) : (
      <button
         type={type ? type : 'button'}
         className={classNames.join(' ')}
         onClick={onClick}
         title={title}
      >
         {children}
      </button>
   )
}

export default Button
