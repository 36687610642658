import { useRouter } from 'next/router'
import { SeoStoryblok } from 'types/storyblok-components'

// Strip cached url from defaultLocale prefix
export const StripDefaultLocale = (slug: string) => {
   const router = useRouter()
   const { defaultLocale } = router || {}

   let href = ''

   if (slug) {
      href =
         slug?.split('/')[0] === defaultLocale
            ? slug.substring(slug.indexOf('/'))
            : slug
   }

   return href
}

export const StripCurrentLocale = (slug: string) => {
   const router = useRouter()
   const { locale } = router || {}

   let href = ''

   // Strip locale from slug
   if (slug) {
      href =
         slug?.split('/')[1] === locale
            ? slug.substring(slug.indexOf(`/${slug.split('/')[2]}`))
            : slug
   }

   return href
}

export const CapitalizeFirstLetter = (string: string) => {
   if (string && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
   } else {
      return string
   }
}

export const isExternalUrl = (url: string) => {
   const isExternal =
      url.substring(0, 4) === 'http' ||
      url.substring(0, 6) === 'mailto' ||
      url.substring(0, 3) === 'tel' ||
      url.substring(0, 9) === 'data:text'
         ? true
         : false

   return isExternal
}

export const getCurrentPage = (pathWithLocale: string, slug: string) => {
   return pathWithLocale?.split('/').length > 1 &&
      slug?.split('/').length > 1 &&
      pathWithLocale.split('/')[1] === slug.split('/')[1]
      ? true
      : pathWithLocale?.split('/').length === 0 &&
        slug?.split('/').length === 0 &&
        pathWithLocale.split('/')[0] === slug.split('/')[0]
      ? true
      : false
}

export const GetLocaleDate = (date: string | null) => {
   const router = useRouter()
   const { defaultLocale, locale } = router || {}

   let formattedDate = ''
   const newDate = date ? new Date(date) : new Date()

   formattedDate = `${newDate.toLocaleDateString(
      locale ? locale : defaultLocale,
      {
         month: 'short',
         day: 'numeric',
         hour: '2-digit',
         minute: '2-digit',
      },
   )}
      `
   return formattedDate
}

export const stringify = (str: string) => {
   if (!str) {
      return ''
   }
   str = str.replace(/^\s+|\s+$/g, '') // trim
   str = str.toLowerCase()

   // remove accents, swap ñ for n, etc
   const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
   const to = 'aaaaeeeeiiiioooouuuunc------'
   for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
   }

   str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-') // collapse dashes

   return str
}

export const getThemeColor = (
   color?: string | number,
): {
   background: string
   color: string
} => {
   switch (color) {
      case 'white':
         return {
            background: 'transparent',
            color: 'var(--black)',
         }
      case 'grey':
         return {
            background: 'var(--light-purple)',
            color: 'var(--black)',
         }
      case 'purple':
         return {
            background: 'var(--purple)',
            color: 'var(--white)',
         }
      // case 'gradiant':
      //    return {
      //       background:
      //          'linear-gradient(180deg, rgba(97,43,68,1) 0%, rgba(73,34,63,1) 51%, rgba(38,23,57,1) 100%)',
      //       color: '#fff',
      //    }
      default:
         return {
            background: 'var(--white)',
            color: 'var(--black)',
         }
   }
}

export const getSeo = (
   seoStoryblok: SeoStoryblok,
   customSeo?: { title?: string; description?: string; image?: string },
) => {
   const isArray = seoStoryblok && seoStoryblok.length > 0
   const seoData = isArray ? seoStoryblok[0] : seoStoryblok ? seoStoryblok : {}

   const {
      seo_description = '',
      seo_title = '',
      seo_image = '',
   } = seoData ? seoData : {}
   const { description, title, image } = customSeo || {}

   return {
      seo_description: seo_description || description || '',
      seo_title: seo_title || title || '',
      seo_image:
         seo_image && seo_image.filename ? seo_image.filename : image || '',
   }
}

export const getHrefFromStoryblokLink = ({ link, tab }: any) => {
   const { cached_url, anchor } = link || {}

   // Format email href
   let { email } = link || {}
   if (email) {
      email = `mailto:${email}`
   }

   // Get href parameters
   const query = tab ? `?tab=${tab}` : ''
   const hash = anchor ? '#' + anchor : ''

   const href = email ? email : cached_url ? `${cached_url}${query}${hash}` : ''
   return href
}

export const updateSearchParamAndHash = ({
   parameter,
   hash,
}: {
   parameter: { name: string; value: string }
   hash: string
}) => {
   if (window && typeof window !== 'undefined') {
      const { name, value } = parameter

      if (name && value && hash) {
         // Set search parameters from tab and section
         const searchParams = new URLSearchParams(window.location.search)
         searchParams.set(name, value)

         const newRelativePathQuery = `${
            window.location.pathname
         }?${searchParams.toString()}#${hash}`

         history.pushState(null, '', newRelativePathQuery)
      }
   }
}
