import React from 'react'
import { PagePostTemplateProps } from './types'
import { Flex, Box, Row, Column, Container } from '@components/UI/Grid/Grid'

import styles from './PagePostTemplate.module.scss'
import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import Label from '@components/UI/Label/Label'

import {
   FacebookShareButton,
   TwitterShareButton,
   LinkedinShareButton,
} from 'react-share'
import { useRouter } from 'next/router'
import LinkComponent from '@components/UI/LinkComponent/LinkComponent'
import Image from '@components/UI/Image/Image'
import NextImage from 'next/image'
import SEOPage from '@components/Head/SEOPage/SEOPage'
import classnames from 'classnames'
import Markdown from '@components/UI/Markdown/Markdown'
import PageNavigation from '@components/UI/PageNavigation/PageNavigation'
import Head from 'next/head'

const PagePostTemplate: React.FC<PagePostTemplateProps> = ({
   blok,
   story,
   funds,
   periodicItems,
   stories,
}) => {
   const {
      label,
      title,
      text,
      image,
      body,
      footer,
      seo_data,
      title_size = 'big',
      image_direction = 'below',
      image_style,
      show_page_navigation = false,
      schemas,
      component,
   } = blok || {}
   const { full_slug = '' } = story || {}
   const { currentNavData, documents } = stories || {}
   // Get root path
   const router = useRouter()
   const { asPath } = router || {}
   let rootPath = asPath.substring(0, asPath.lastIndexOf('/'))
   rootPath = rootPath.substring(0, rootPath.lastIndexOf('/'))

   // Get absolute path for sharing
   let shareUrl = ''
   if (typeof window != 'undefined' && window.location) {
      shareUrl = window.location.href
   }

   const navItems =
      body && body.length > 0
         ? body.filter((blok: any) => blok.label).map((blok: any) => blok.label)
         : []
   const hasImage = image?.filename
   return (
      <React.Fragment>
         {schemas && schemas.length > 0 && (
            <Head>
               {schemas.map((schema: any) => (
                  <script
                     key={schema._uid}
                     type="application/ld+json"
                     dangerouslySetInnerHTML={{ __html: schema.schema }}
                  />
               ))}
            </Head>
         )}
         <SEOPage
            storyblokData={seo_data}
            customData={{
               title: title || story?.name || '',
               image: image?.filename || '',
               description: text || '',
            }}
         />

         <Box
            className={classnames(
               styles.hero,
               hasImage && image_direction === 'below' ? styles.heroImage : '',
            )}
            display="flex"
         >
            <Container className={styles.content}>
               <Row
                  alignItems={
                     hasImage && image_direction === 'below'
                        ? 'flex-end'
                        : 'center'
                  }
                  justifyContent={
                     hasImage && image_direction === 'right'
                        ? 'space-between'
                        : 'center'
                  }
               >
                  <Column
                     width={[
                        1,
                        null,
                        null,
                        hasImage && image_direction === 'right'
                           ? 6 / 12
                           : 10 / 12,
                     ]}
                  >
                     <Box
                        paddingBottom={hasImage ? 0 : [15, null, null, 0]}
                        paddingRight={
                           hasImage && image_direction === 'right'
                              ? [0, null, null, 10]
                              : 0
                        }
                        className={styles.innerContent}
                     >
                        <Flex
                           flexDirection="column"
                           alignItems="flex-start"
                           justifyContent="center"
                        >
                           {label && (
                              <Box marginBottom={[4, null, null, 6]}>
                                 <Label title={label} />
                              </Box>
                           )}
                           {title && (
                              <Flex
                                 as="h1"
                                 className={
                                    title_size === 'small'
                                       ? styles.smallSize
                                       : ''
                                 }
                              >
                                 <Box>{title}</Box>
                              </Flex>
                           )}
                           {text && (
                              <Box marginTop={[8]} className={styles.text}>
                                 <Markdown>{text}</Markdown>
                              </Box>
                           )}
                        </Flex>
                        {hasImage && image_direction === 'below' && (
                           <Box
                              marginTop={[8, null, null, 12]}
                              className={classnames(
                                 styles.imageWrapper,
                                 styles.imageBelow,
                                 !image_style || image_style === 'rounded'
                                    ? styles.imageRounded
                                    : '',
                              )}
                              alignSelf="center"
                           >
                              <Box className={styles.image}>
                                 <Image src={image.filename} />
                              </Box>
                           </Box>
                        )}
                     </Box>
                  </Column>
                  {hasImage && image_direction === 'right' && (
                     <Column width={[1, null, null, 6 / 12]}>
                        <Box
                           className={classnames(
                              styles.imageWrapper,
                              image_style === 'rounded'
                                 ? styles.imageRounded
                                 : '',
                           )}
                           alignSelf="center"
                           marginTop={[8, null, null, 0]}
                           marginBottom={[5, null, null, 0]}
                        >
                           <Box>
                              <Image src={image.filename} />
                           </Box>
                        </Box>
                     </Column>
                  )}
               </Row>
            </Container>
         </Box>

         <Flex flexDirection="column" width={1} className={styles.body}>
            {hasImage && image_direction === 'below' && (
               <Container>
                  <Row justifyContent="center">
                     <Column
                        width={[1, null, null, 10 / 12]}
                        marginBottom={[6, null, null, 8]}
                     >
                        <Box className={styles.ghost} />
                     </Column>
                  </Row>
               </Container>
            )}
            <PageNavigation
               links={show_page_navigation ? navItems : []}
               url={full_slug}
            />
            {body &&
               body?.length > 0 &&
               body.map((blok: any) => {
                  return (
                     <DynamicComponent
                        blok={blok}
                        key={blok._uid}
                        currentNavData={currentNavData}
                        documents={documents}
                        periodicItems={
                           blok.component === 'periodic_system'
                              ? periodicItems
                              : null
                        }
                        isNews={component === 'page_news'}
                        funds={
                           blok.component === 'fund_list' ||
                           blok.component === 'investors'
                              ? funds
                              : null
                        }
                     />
                  )
               })}
            <Box
               as="section"
               className={styles.footer}
               marginTop={[10, null, null, 15]}
               paddingBottom={[10, null, null, 15]}
            >
               <Container>
                  <Box
                     className={styles.border}
                     paddingTop={[10, null, null, 15]}
                  />
                  <Flex justifyContent="space-between" alignItems="center">
                     <LinkComponent href={rootPath} title="Back">
                        <Flex alignItems="center" className={styles.back}>
                           <NextImage
                              src="/icons/arrow.svg"
                              alt="arrow"
                              width="36"
                              height="27"
                           />
                           <Box as="span" marginLeft={[2]}>
                              Back
                           </Box>
                        </Flex>
                     </LinkComponent>
                     <Flex className={styles.share} alignItems="center">
                        <FacebookShareButton
                           url={shareUrl}
                           className={styles.shareButton}
                           title="Share this article on Instagram"
                        >
                           <NextImage
                              src="/icons/facebook-purple.svg"
                              width="26"
                              height="26"
                              alt="Facebook"
                           />
                        </FacebookShareButton>

                        <TwitterShareButton
                           url={shareUrl}
                           className={styles.shareButton}
                           title="Share this article on X"
                        >
                           <NextImage
                              src="/icons/x-purple.svg"
                              width="26"
                              height="26"
                              alt="X"
                           />
                        </TwitterShareButton>
                        <LinkedinShareButton
                           url={shareUrl}
                           className={styles.shareButton}
                           title="Share this article on LinkedIn"
                        >
                           <NextImage
                              src="/icons/linkedin-purple.svg"
                              width="26"
                              height="26"
                              alt="Linkedin"
                           />
                        </LinkedinShareButton>
                     </Flex>
                  </Flex>
               </Container>
            </Box>
            {footer &&
               footer?.length > 0 &&
               footer.map((blok: any) => {
                  return <DynamicComponent blok={blok} key={blok._uid} />
               })}
         </Flex>
      </React.Fragment>
   )
}

export default PagePostTemplate
