import { Box, Container, Flex } from '@components/UI/Grid/Grid'
import Label from '@components/UI/Label/Label'
import React, { useEffect, useRef } from 'react'
import styles from './IframeWidget.module.scss'
import Markdown from '@components/UI/Markdown/Markdown'
import { stringify } from '@lib/helpers'

const IframeWidget = ({ blok }: any) => {
   const ref: any = useRef(null)
   const { widget_code, first_script, second_script, label, title, text } = blok

   useEffect(() => {
      const externalScript = document.createElement('script')
      const inlineScript = document.createElement('script')

      const generateScript = new Promise((resolve) => {
         const script = document.createElement('script')
         script.id = first_script
         script.src = first_script
         script.type = 'text/javascript'
         script.onload = resolve

         document.head.appendChild(script)
      })

      const loadWidget = () => {
         inlineScript.innerHTML = second_script
         ref.current.append(inlineScript)
      }

      generateScript.then(() => loadWidget())

      return () => {
         externalScript.remove()
         inlineScript.remove()
      }
   }, [])
   const sectionID = label ? stringify(label) : ''

   return (
      <Flex as="section" style={{ position: 'relative' }}>
         <Container paddingY={[10, null, null, 20]}>
            <div id={sectionID} className="anchor" />
            <Flex
               width={[1, null, null, 6 / 12]}
               flexDirection={'column'}
               alignItems={'flex-start'}
            >
               {label && (
                  <Box marginBottom={[4, null, null, 6]}>
                     {label && <Label title={label} />}
                  </Box>
               )}
               {title && (
                  <Box
                     className={styles.title}
                     as="h3"
                     marginBottom={[5, null, null, 10]}
                  >
                     {title}
                  </Box>
               )}
               {text && (
                  <Box
                     marginBottom={[5, null, null, 10]}
                     width={1}
                     className={styles.intro}
                  >
                     <Markdown>{text}</Markdown>
                  </Box>
               )}
            </Flex>
            <div className={styles.widget} ref={ref}>
               <div dangerouslySetInnerHTML={{ __html: widget_code }} />
            </div>
         </Container>
      </Flex>
   )
}

export default IframeWidget
