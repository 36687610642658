
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import type { NextPage, GetStaticPropsContext, GetStaticPathsContext, } from 'next';
import DynamicComponent from '@components/DynamicComponent/DynamicComponent';
import Storyblok, { useStoryblok } from '@lib/storyblok';
import { getChartData } from '@lib/getChartData';
import { config } from 'config';
import { SbProps } from 'types/storyblok-props';
import { SbEditableContent } from 'storyblok-react';
import React from 'react';
import { fundNameToSlug, fundSlugToName, fundToCleanName, } from '@lib/fundNameToSlug';
import { getLatestData } from '@lib/charthelpers';
export interface StoryProps extends SbProps {
    blok?: SbEditableContent;
    preview: boolean;
    funds: any;
    periodicItems: any;
    holdingData: any;
}
const Story: NextPage<StoryProps> = ({ story, stories, preview = false, funds, periodicItems, holdingData, }) => {
    story = useStoryblok(story, preview);
    const parsedStory = {
        name: story?.name ? story.name : '',
        full_slug: story?.full_slug ? story.full_slug : '',
        published_at: story?.published_at ? story.published_at : '',
    };
    return (<DynamicComponent blok={story?.content} story={parsedStory} stories={stories} funds={funds} periodicItems={periodicItems} holdingData={holdingData}/>);
};
export async function getStaticPaths({ locales }: GetStaticPathsContext) {
    // the storyblok params
    const sbParams = {
        version: process.env.PROJECT_ENVIRONMENT === 'production' ||
            process.env.PROJECT_ENVIRONMENT === 'staging'
            ? 'published'
            : 'draft',
        cv: Date.now(),
    };
    const { data } = await Storyblok.get('cdn/links/', sbParams);
    const paths: [
        {
            params: {
                slug: string;
            };
            locale?: string;
        }?
    ] = [];
    Object.keys(data.links).forEach((linkKey) => {
        // remove folder items
        if (data.links[linkKey].is_folder) {
            return;
        }
        // get array for slug because of catch all
        let slug = data.links[linkKey].slug;
        if (slug.includes('settings')) {
            return;
        }
        if (slug === 'home') {
            slug = '/';
        }
        // const slugParts =
        //    slug && slug.split('/').length > 0
        //       ? slug.split('/').map((slugPart: string, index: number) => {
        //            // If localization is inactivated, set home slug to root path
        //            return !defaultLocale &&
        //               index === 0 &&
        //               slugPart === config?.storyblok?.home?.slug
        //               ? '/'
        //               : slugPart
        //         })
        //       : []
        // get locale from slug and remove from slug array
        // const locale = slugParts && slugParts?.length > 0 ? slugParts[0] : ''
        // const slugPartsWithoutLocale = !defaultLocale
        //    ? slugParts
        //    : slugParts.slice(1, slugParts.length)
        locales?.forEach((locale: string) => {
            paths.push({ params: { slug: slug.split('/') }, locale });
        });
    });
    return {
        paths: paths,
        fallback: false,
    };
}
export const postReducer = (post: any) => {
    return {
        full_slug: post.full_slug,
        first_published_at: post.first_published_at,
        content: {
            title: post.content.title || null,
            excerpt: post.content.excerpt || null,
            component: post.content.component || null,
            video: post.content.video || null,
            image: post.content.image || null,
            duration: post.content.duration || null,
            description: post.content.description || null,
            list_image: post.content.list_image || null,
        },
    };
};
const getCurrentNavData = async () => {
    const items = [
        'silverBulletA',
        'silverBulletB',
        'preciousGreenA',
        'preciousGreenB',
        'goldMining',
        'goldMiningDailyA',
        'goldMiningDailyB',
        'essentialMetalsA',
        'essentialMetalsB',
    ];
    const response = await Promise.all(items.map(async (slug: string) => {
        const data = await getChartData(slug, true);
        return {
            slug,
            data: data.data,
        };
    }));
    return response;
};
const categoryReducer = (post: any) => {
    return {
        full_slug: post.full_slug,
        slug: post.slug,
    };
};
async function getStaticProps({ params, locale, preview = false, }: GetStaticPropsContext) {
    const nextSlug = params?.slug
        ? (params.slug as Array<string>).join('/')
        : 'home';
    // the slug of the story
    // const storyblokSlug = `${locale ? `${locale}/` : ``}${
    //    !nextSlug && !defaultLocale ? config?.storyblok?.home?.slug : nextSlug
    // }`
    // the storyblok params
    const sbParams = {
        version: process.env.PROJECT_ENVIRONMENT === 'production' ||
            process.env.PROJECT_ENVIRONMENT === 'staging'
            ? 'published'
            : 'draft',
        cv: Date.now(),
        language: locale,
        resolve_relations: config?.storyblok?.resolveRelations &&
            config.storyblok.resolveRelations?.length > 0
            ? config.storyblok.resolveRelations.join(',')
            : [],
    };
    // checks if Next.js is in preview mode
    if (preview || process.env.PROJECT_ENVIRONMENT === 'development') {
        // loads the draft version
        sbParams.version = 'draft';
        // appends the cache version to get the latest content
        sbParams.cv = Date.now();
    }
    // loads the story from the Storyblok API
    // since slug is striped from locales in next.config, add locale to slug
    const { data } = await Storyblok.get(`cdn/stories/${nextSlug}`, sbParams);
    // fetch custom story data and add to selected page templates
    const stories = {
        posts: [],
        currentNavData: [],
        documents: null,
        navData: [],
        navDataDailyA: [],
        navDataDailyB: [],
        sharedNavData: [],
        categories: [],
        total: 0,
        slug: '',
    };
    if (data?.story?.content?.component === 'page_post_landing') {
        const { data: category_pages } = await Storyblok.get(`cdn/stories/?filter_query[component][in]=page_post_landing`, { ...sbParams, per_page: 14 });
        let sbString = `cdn/stories/?starts_with=${nextSlug}&sort_by=first_published_at:desc&filter_query[component][in]=page_article,page_podcast,page_publication,page_video,page_news,page_portfolio_updates`;
        if (nextSlug === 'research-centre/resources') {
            sbString = `cdn/stories/?starts_with=${nextSlug}&sort_by=first_published_at:desc&filter_query[component][in]=page_article,page_podcast,page_publication,page_video,page_news,page_portfolio_updates,page_seo`;
        }
        const { data: posts, total: posts_total } = await Storyblok.get(sbString, { ...sbParams, per_page: 14 });
        stories['posts'] =
            posts?.stories && posts.stories.length > 0
                ? posts.stories.map(postReducer)
                : [];
        stories['categories'] =
            category_pages?.stories && category_pages.stories.length > 0
                ? category_pages.stories.map(categoryReducer)
                : [];
        stories['total'] = posts_total;
        stories['slug'] = nextSlug;
    }
    const content = data?.story?.content;
    if (data?.story?.content?.component === 'page_fund') {
        const product = content && content.hero && content.hero[0] && content.hero[0].product;
        if (product) {
            const sharedProduct = product && product !== fundNameToSlug(product)
                ? product.endsWith(' B')
                    ? product.slice(0, -2) + ' A'
                    : product.slice(0, -2) + ' B'
                : null;
            const response: any = await getChartData(fundNameToSlug(product), true);
            if (sharedProduct) {
                const sharedResponse: any = await getChartData(fundNameToSlug(sharedProduct), true);
                stories['sharedNavData'] = sharedResponse.data;
            }
            //  GOLD MINING SPECIFIC
            let responseDailyA: any = null;
            let responseDailyB: any = null;
            if (fundNameToSlug(product) === 'goldMining') {
                responseDailyA = await getChartData('goldMiningDailyA', true);
                responseDailyB = await getChartData('goldMiningDailyB', true);
            }
            stories['navData'] = response.data;
            if (responseDailyA) {
                stories['navDataDailyA'] = responseDailyA.data;
            }
            if (responseDailyB) {
                stories['navDataDailyB'] = responseDailyB.data;
            }
        }
        // stories['navData'] = navData
    }
    let documents: any = null;
    let needDocuments = false;
    if (data.story.content.body) {
        let needNavData = false;
        data.story.content.body.forEach((inner: any) => {
            if (inner.component === 'current_nav') {
                needNavData = true;
            }
            if (inner.component === 'information_and_documents') {
                needDocuments = true;
            }
        });
        if (needDocuments) {
            const docResponse = await Storyblok.get(`cdn/stories/settings/documents`, sbParams);
            documents =
                docResponse &&
                    docResponse.data &&
                    docResponse.data.story.content.files;
            data.story.content.body.map((inner: any) => {
                if (inner.component === 'information_and_documents') {
                    stories['documents'] = documents;
                    return inner;
                }
                else {
                    return inner;
                }
            });
        }
        if (needNavData) {
            const navData: any = await getCurrentNavData();
            data.story.content.body.map((inner: any) => {
                if (inner.component === 'current_nav') {
                    const parsedNavData: any = [];
                    navData.forEach((item: any) => {
                        if (item.slug === 'goldMining') {
                            const data = getLatestData(fundSlugToName(item.slug), item.data);
                            parsedNavData.push({
                                ...data,
                                name: fundToCleanName('AuAg Gold Mining - ETF (USD)'),
                            });
                            parsedNavData.push({
                                ...data,
                                name: fundToCleanName('AuAg Gold Mining - ETF (GBP)'),
                                price: data.gbp,
                                percentage: data.gbpPercentage,
                                currency: 'GBP',
                            });
                            parsedNavData.push({
                                ...data,
                                name: fundToCleanName('AuAg Gold Mining - ETF (CHF)'),
                                price: data.chf,
                                percentage: data.chfPercentage,
                                currency: 'CHF',
                            });
                            parsedNavData.push({
                                ...data,
                                name: fundToCleanName('AuAg Gold Mining - ETF (EUR)'),
                                price: data.euro,
                                percentage: data.euroPercentage,
                                currency: 'EUR',
                            });
                        }
                        else {
                            const data = getLatestData(fundSlugToName(item.slug), item.data);
                            parsedNavData.push({
                                ...data,
                                name: fundToCleanName(data.name),
                            });
                        }
                    });
                    stories['currentNavData'] = parsedNavData;
                    return inner;
                }
                else {
                    return inner;
                }
            });
        }
    }
    // return the story from Storyblok and whether preview mode is active
    return {
        props: {
            story: data ? data.story : null,
            stories: stories,
            preview: preview,
        },
    };
}
export default Story;

    async function __Next_Translate__getStaticProps__1918ecdbabe__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/[[...slug]]',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1918ecdbabe__ as getStaticProps }
  