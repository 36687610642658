import React from 'react'
import { PageDefaultProps } from './types'
import { Flex } from '@components/UI/Grid/Grid'

import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import { HeroStoryblok } from 'types/storyblok-components'
import PageNavigation from '@components/UI/PageNavigation/PageNavigation'
import SEOPage from '@components/Head/SEOPage/SEOPage'
import { useRouter } from 'next/router'
import Head from 'next/head'

const PageDefault: React.FC<PageDefaultProps> = ({
   blok,
   story,
   funds,
   periodicItems,
   holdingData,
   stories,
}) => {
   const { locale } = useRouter()
   const { currentNavData, documents } = stories || {}

   const { body, hero, seo_data, schemas } = blok || {}
   const { full_slug = '' } = story || {}

   const navItems =
      body && body.length > 0
         ? body.filter((blok: any) => blok.label).map((blok: any) => blok.label)
         : []

   return (
      <React.Fragment>
         {schemas && schemas.length > 0 && (
            <Head>
               {schemas.map((schema: any) => (
                  <script
                     key={schema._uid}
                     type="application/ld+json"
                     dangerouslySetInnerHTML={{ __html: schema.schema }}
                  />
               ))}
            </Head>
         )}
         <SEOPage
            storyblokData={seo_data}
            customData={{
               title: hero?.[0]?.title || story?.name || '',
               description: hero?.[0]?.subtitle || '',
            }}
         />
         {hero &&
            hero?.length > 0 &&
            hero.map((blok: HeroStoryblok) => {
               return <DynamicComponent blok={blok} key={blok._uid} />
            })}
         <PageNavigation links={navItems} url={full_slug} />
         <Flex flexDirection="column" width={1}>
            {body &&
               body?.length > 0 &&
               body.map((blok: any, index: number) => {
                  return (
                     <DynamicComponent
                        blok={{ ...blok, isLast: index === body.length - 1 }}
                        key={`${blok._uid}-${locale}`}
                        currentNavData={currentNavData}
                        documents={documents}
                        periodicItems={
                           blok.component === 'periodic_system'
                              ? periodicItems
                              : null
                        }
                        funds={
                           blok.component === 'fund_list' ||
                           blok.component === 'investors'
                              ? funds
                              : null
                        }
                        holdingData={
                           blok.component === 'chart_and_holdings'
                              ? holdingData
                              : null
                        }
                     />
                  )
               })}
         </Flex>
      </React.Fragment>
   )
}

export default PageDefault
