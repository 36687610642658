import React from 'react'
import { FundListProps } from './types'
import styles from './FundList.module.scss'
import { Flex, Container, Box } from '@components/UI/Grid/Grid'
import Label from '@components/UI/Label/Label'
import { getThemeColor, stringify } from '@lib/helpers'
import Funds from '@components/Funds/Funds'
import { LinkStoryblok } from 'types/storyblok-components'
import CtaButton from '@components/CtaButton/CtaButton'
import Markdown from '@components/UI/Markdown/Markdown'
import Image from '@components/UI/Image/Image'

const FundList: React.FC<FundListProps> = ({ blok, funds }) => {
   const {
      label,
      title,
      description,
      background,
      start_fund,
      start_investor,
      start_country,
      cta,
      highlight_platforms,
   } = blok

   const highlight_title =
      highlight_platforms &&
      highlight_platforms[0] &&
      highlight_platforms[0].title
   const highlight_description =
      highlight_platforms &&
      highlight_platforms[0] &&
      highlight_platforms[0].description
   const highlight_links =
      highlight_platforms &&
      highlight_platforms[0] &&
      highlight_platforms[0].platforms

   return (
      <Flex
         as="section"
         className={styles.wrapper}
         style={{ background: getThemeColor(background).background }}
         color={getThemeColor(background).color}
      >
         <div id={label ? stringify(label) : ''} className="anchor" />
         <Container>
            <Flex
               flexDirection={['column', null, null, 'row']}
               alignItems={['flex-start', null, null, 'flex-end']}
               paddingBottom={[5, null, null, 10]}
               className={styles.intro}
            >
               <Flex
                  flexDirection="column"
                  alignItems="flex-start"
                  className={styles.content}
                  width={1}
                  paddingTop={[10, null, null, 20]}
               >
                  <Box marginBottom={[4, null, null, 6]}>
                     {label && <Label title={label} />}
                  </Box>
                  <Box
                     as="h3"
                     className={styles.title}
                     marginBottom={[5, null, null, 6]}
                  >
                     {title}
                  </Box>
                  {description && (
                     <Box
                        width={[1, null, null, 5 / 12]}
                        as="p"
                        className={styles.text}
                     >
                        {description}
                     </Box>
                  )}
               </Flex>
               {cta &&
                  cta?.length > 0 &&
                  cta.map((blok: LinkStoryblok) => {
                     return (
                        <CtaButton
                           key={blok._uid}
                           blok={{
                              _uid: '',
                              component: 'cta_button',
                              link: [{ ...blok }],
                              color: 'purple',
                              hover_color: 'purple',
                           }}
                        />
                     )
                  })}
            </Flex>

            {funds && (
               <Funds
                  key={start_investor}
                  start_fund={start_fund}
                  start_investor={start_investor}
                  start_country={start_country}
                  background={getThemeColor('grey').background}
                  funds={funds}
               />
            )}
            {highlight_platforms && highlight_platforms.length > 0 && (
               <div className={styles.highlights}>
                  <div className={styles.highlightContent}>
                     <h3>{highlight_title}</h3>
                     {highlight_description && (
                        <Markdown>{highlight_description}</Markdown>
                     )}
                  </div>
                  <div className={styles.highlightPartners}>
                     {highlight_links &&
                        highlight_links.map((partner: any) => (
                           <div className={styles.item} key={partner.uid}>
                              <div className={styles.logo}>
                                 <div>
                                    <Image
                                       alt={partner.logotype.alt}
                                       src={partner.logotype.filename}
                                    />
                                 </div>
                              </div>
                              <CtaButton
                                 blok={{
                                    component: 'cta_button',
                                    _uid: 'test',
                                    link: partner.link,
                                    color: 'purple',
                                    hover_color: 'purple',
                                 }}
                              />
                           </div>
                        ))}
                  </div>
               </div>
            )}
         </Container>
      </Flex>
   )
}

export default FundList
