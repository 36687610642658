export const config: {
   storyblok?: {
      settings?: { slug?: string; component?: string }
      chartData?: { slug?: string; component?: string }
      holdingData?: { slug?: string; component?: string }
      home?: { slug?: string; component?: string }
      resolveRelations?: string[] | [] | [string]
   }
} = {
   storyblok: {
      settings: { slug: 'settings', component: 'settings' },
      chartData: { slug: 'settings/chart-data', component: 'chart_data' },
      holdingData: {
         slug: 'settings/holding-data',
         component: 'holding_data',
      },
      home: { slug: 'home', component: 'page_home' },
      resolveRelations: ['featured_posts.posts', 'selected_posts.posts'],
   },
}
